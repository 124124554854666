@font-face {
    font-family: 'Roboto Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto Thin'), url('./assets/fonts/Roboto-Thin.ttf') format('ttf');
}@font-face {
    font-family: 'Roboto Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto Light Italic'), url('./assets/fonts/Roboto-LightItalic.ttf') format('ttf');
}
@font-face {
    font-family: 'Roboto Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto Regular'), url('./assets/fonts/Roboto-Regular.ttf') format('ttf');
}
@font-face {
    font-family: 'Roboto Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto Bold'), url('./assets/fonts/Roboto-Bold.ttf') format('ttf');
}

@font-face {
    font-family: 'Roboto Black';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto Black'), url('./assets/fonts/Roboto-Black.ttf') format('ttf');
}
@font-face {
    font-family: 'Roboto Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto Italic'), url('./assets/fonts/Roboto-Italic.ttf') format('ttf');
}
@font-face {
    font-family: 'Roboto Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto Medium'), url('./assets/fonts/Roboto-Medium.ttf') format('ttf');
}
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto Condensed'), url('./assets/fonts/RobotoCondensed-VariableFont_wght.ttf') format('ttf');
}



.container {
    display: flex;
    background-image: url('./assets/bg.png');
    background-position: right top;
    background-size: 1200px;
    background-repeat: no-repeat;
    height: 100%

}

.containerMobile {
    display: flex;
    background-image: url('./assets/bg.png');
    background-position: right top;
    background-size: 350px;
    background-repeat: no-repeat;
    margin-top: 40px;

}

.menuLateral {
    display: 'flex';
    font-family: 'Roboto Condensed' !important;
    height: 70px;
    border: none;
    color: #479e97 !important;
    font-size: 16px !important;
    text-align: left !important;
    justify-content: left !important;
    cursor: pointer;
}

.menuLateralSmallScreen {
    display: 'flex';
    font-family: 'Roboto Condensed' !important;
    height: 50px;
    border: none;
    color: #479e97 !important;
    font-size: 16px !important;
    text-align: left !important;
    justify-content: left !important;
    cursor: pointer;
}
.boxRounded {
    height: 100px;
    width: 120px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #5abdc4;
    border: none
}
.boxRoundedMobile {
    height: 50px;
    width: 60px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #5abdc4;
    border: none
}
.boxRounded2 {
    font-size: 20px;
    width: 220px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #10336f;
    color: #79bcc1;
    position: absolute;
    z-index: 100;
    margin-left: 140px;
    padding-top: 15px;
}
.boxRounded3 {
    height: 220px;
    font-size: 20px;
    width: 280px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #5abdc4;
    color: #ffffff
}
.boxRounded4 {
    height: 120px;
    font-size: 20px;
    width: 300px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #10336f;
    color: #79bcc1;
}
.opaco {
    backdrop-filter: blur(4px) saturate(152%);
    -webkit-backdrop-filter: blur(4px) saturate(152%);
    background-color: '#ffffff';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
}
.bordaInterna {
    padding: 40px;
    padding-left: 40px;
    border: 1px solid #79bcc1;
    border-bottom: none;
    border-right: none;
    /* border-image: 
        linear-gradient(
        to bottom, 
        #79bcc1, 
        rgba(0, 0, 0, 0)
        ) 1 100%;
    */
}
.sair {
    background-color: transparent;
    border: none;
}
.inputMasked {
    padding:16px;
    margin:8px;
    border: 1px solid #c3c4c4;
    border-radius: 2px;
    color: #686868;
    font-size: 16px;
}
.enviar {
    background-color: #10336f;
    color: #ffffff;
    border: none;
    display: flex;
    width: 120px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 12px;
    margin-left: 10px;
    font-size: 17px;
}
.enviarOff {
    background-color: #cccccc;
    color: #ffffff;
    border: none;
    display: flex;
    width: 120px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 12px;
    margin-left: 10px;
    font-size: 17px;
}